<template>
  <section id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-2">
        {{ $t("Pages.MasterCoin.Title.master_coin") }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t("Pages.MasterCoin.Title.by_buying_coins") }}
      </p>
    </div>
    <!--/ title text and switch button -->

    <b-modal
      id="modal-coins"
      :title="$t('Pages.MasterCoin.Title.payment_confirm')"
      ok-only
      :ok-title="$t('Pages.MasterCoin.Button.Payment')"
      @ok="goToPaymentPage"
    >
      <b-card-text>
        <h4>
          {{
            $t("Pages.MasterCoin.Title.name_package") + " : " + selectPack.name
          }}
        </h4>
        <h5 class="mb-1">
          {{
            $t("Pages.MasterCoin.Title.price_package") +
            " : " +
            selectPack.price +
            " " +
            selectPack.currency
          }}
        </h5>
        <b-input-group>
          <b-form-input v-model="coupon" />
          <b-input-group-append>
            <b-button variant="primary" @click="validationCoupon">{{
              $t("Pages.Plan.Button.regis_discount")
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <p class="mt-2" v-if="price_discount_status">
          <span v-if="price_discount > 0">
            {{
              $t("Pages.Plan.Title.amount_payable : {price}", {
                price: price_discount,
              })
            }}
          </span>
          <span v-else>
            {{
              $t("Pages.Plan.Title.amount_payable : {price}", {
                price: $t("Pages.Plan.Title.Free"),
              })
            }}
          </span>
        </p>
      </b-card-text>
    </b-modal>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="12" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row class="d-flex justify-content-center match-height">
          <b-col md="3" v-for="pack in packs" :key="pack.id">
            <b-card class="popular" align="center">
              <!-- img -->
              <b-img :src="pack.icon" class="mb-1" width="70" alt="svg img" />
              <!--/ img -->
              <h1>{{ pack.name }}</h1>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{
                  }}</sup>
                  <span class="pricing-basic-value d-inline-block font-weight-bolder text-primary">{{ pack.price.toString() }}</span>
                  <sub class="font-medium-1 d-inline-block font-weight-bold text-primary">{{
                    pack.currency
                  }}</sub>
                </div>

                <del class="annual-pricing text-muted">
                  {{ pack.original_price.toString() }}
                  {{ pack.currency }}</del
                >
              </div>

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                v-b-modal.modal-coins
                @click="pay(pack)"
              >
                <span>{{ $t("Pages.MasterCoin.Button.buy_back") }}</span>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectPack: {},
      packs: [],
      price_discount: 0,
      price_discount_status: false,
      coupon: null,
    };
  },
  created() {
    this.getListPackage();
  },
  methods: {
    getListPackage() {
      this.$http.get("/api/coin/packages").then((response) => {
        this.packs = response.data.result;
      });
    },
    validationCoupon() {
      this.$http
        .post("/api/coupon/check/coin-pack", {
          id: this.selectPack.id,
          code: this.coupon,
        })
        .then(({ data }) => {
          if (data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.MasterCoin.Alert.success_discount_applied"),
              "success"
            );
            this.price_discount = data.price;
            this.price_discount_status = true;
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Pages.MasterCoin.Alert.discount_invalid"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.price_discount = null;
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.MasterCoin.Alert.discount_invalid"),
            "danger"
          );
        });
    },
    pay(pack) {
      this.selectPack = pack;
    },
    goToPaymentPage() {
      this.makeToast(
        this.$t("Base.Alert.please_wait"),
        this.$t("Pages.MasterCoin.Alert.transfer_portal"),
        "success"
      );
      this.$http
        .post(`/api/coin/packages/pay`, {
          id: this.selectPack.id,
          coupon: this.coupon,
        })
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.MasterCoin.Alert.pay_gateway"),
            "danger"
          );
        });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
